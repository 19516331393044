<template>
    <div class="jumbotron jumbotron-fluid"
         v-bind:style="{ 'backgroundColor': color, 'color': textColor }">
        <div class="container d-flex justify-content-center align-items-center"
             v-bind:data-aos="revealAnimation">
            <h1 class="display-5 d-flex justify-content-center align-items-center"
                v-bind:style="underline ? underlineStyles : {}">
                <span v-for="char in title">
                    {{ char }}
                </span>
            </h1>
            <p class="lead text-center"
               v-if="subtitle">{{ subtitle }}</p>
        </div>
        <footer>
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'ViewTitle',
        props: {
            title: {
                type: String
            },
            titleStyles: {
                type: Object
            },
            subtitle: {
                type: String
            },
            subtitleStyles: {
                type: Object
            },
            underline: {
                type: Boolean,
                default: false
            },
            underlineColor: {
                type: String,
                default: 'rgba(83, 87, 90, 1)'
            },
            color: {
                type: String,
                default: 'rgba(246, 246, 246, 1)'
            },
            textColor: {
                type: String,
                default: 'rgba(113, 113, 113, 1)'
            },
            revealAnimation: {
                type: String
            }
        },
        data() {
            return {
                underlineStyles: {
                    'paddingBottom': '15px',
                    'borderBottom': 'solid 2px',
                    'borderBottomColor': this.underlineColor
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    $theme-colors: (
        "dark": rgba(113, 113, 113, 1)
    );
    @import "../../../node_modules/bootstrap/scss/bootstrap";

    .jumbotron {
        position: relative;
        margin-bottom: 0;
        padding: 3rem 2rem;

        h1 {
            font-weight: 500;

            & > span {
                margin-right: 7.5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        footer {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
</style>
