<template>
    <section class="home">
        <div class="bg-filter position-fixed">

        </div>
        <jumbotron class="headline"
                   v-bind:bg-filter-styles="headlineFilterStyles">
            <template slot="bg-clip">
                <div class="position-absolute clip-wrapper"
                     data-aos="fade-up"
                     data-aos-duration="1000"
                     data-aos-delay="900">
                    <img id="clip" src="../../assets/home_bg_clip.png">
                </div>
            </template>
            <h1 class="mb-4 mb-md-5 text-white font-weight-light"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="300">
                停下時光 靜止衰老
            </h1>
            <h1 class="text-white font-weight-bold"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="700">
                以科研逆轉肌齡
            </h1>
        </jumbotron>

        <view-title title="簡介" underline color="white" reveal-animation="fade-down"></view-title>
        <jumbotron class="intro"
                   v-bind:bg-filter-styles="introFilterStyles">
            <h4 class="text-center mb-4 font-weight-normal" data-aos="fade-in">
                Marier Skincare深信幹細胞對肌膚的重要性，與韓國頂尖科研團隊合作，於世界各地搜羅最優質原材料，針對肌膚衰老問題。
            </h4>
            <h4 class="text-center mb-4 font-weight-normal" data-aos="fade-in">
                以高科技技術研製高效抗衰老產品，成功研發出針對幹細胞生長，有高濃度親和性的人體骨髓幹細胞培養液SCCM。
            </h4>
            <h4 class="text-center font-weight-normal" data-aos="fade-in">
                能強效抗衰老，高速修復受損細胞，為肌膚補充水分營養， 是世界No.1幹細胞權威專家。
            </h4>
        </jumbotron>

        <view-title title="皇牌產品" underline color="white" reveal-animation="fade-down"></view-title>
        <div class="featurettes">
            <div class="container p-0">
                <div class="row no-gutters">
                    <div class="col-md-6 mb-5 mb-md-0 px-2 px-lg-0"
                         v-for="product in featuredProducts">
                        <featurette class=""
                                    data-aos="fade-in"
                                    v-bind:direction="product.direction"
                                    v-bind:to="product.to"
                                    v-bind:image="product.image"
                                    v-bind:description="product.description">
                            <template slot="title">
                                <div v-html="product.title"></div>
                            </template>
                        </featurette>
                    </div>
                </div>
            </div>
        </div>

        <view-title title="皇牌療程" underline color="white" reveal-animation="fade-down"></view-title>
        <div class="featurettes pb-5">
            <div class="container p-0">
                <featurette class=""
                            v-for="treatment in featuredTreatments"
                            v-bind:key="treatment._id"
                            v-bind:direction="treatment.direction"
                            v-bind:to="treatment.to"
                            v-bind:image="treatment.image"
                            v-bind:description="treatment.description">
                    <template slot="title">
                        <div v-html="treatment.title"></div>
                    </template>
                </featurette>
            </div>
        </div>
    </section>
</template>

<script>
    import Jumbotron from '../Jumbotron'
    import ViewTitle from '../layouts/ViewTitle'
    import Featurette from '../Featurette'

    export default {
        name: 'home',
        components: {
            Featurette,
            ViewTitle,
            Jumbotron
        },
        data() {
            return {}
        },
        computed: {
            headlineFilterStyles() {
                return {
                    backgroundImage: "url('./home_bg.png')",
                    backgroundPosition: 'center'
                }
            },

            introFilterStyles() {
                return {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(1px)'
                }
            },

            featuredProducts() {
                return [{
                    _id: '5b65c9176df87dbf87932cb4',
                    direction: 'top',
                    to: '/',
                    title: "<img src='../../assets/logo_sccm.png' alt='SCCM' style='width: 100%; padding-left: 15px; padding-right: 15px; margin-bottom: -25px;'>",
                    image: './image_sccm.png',
                    description: '<p>肌膚年齡，由妳一手逆轉。世界權威護膚專家Marier，聯同韓國頂尖科研團隊Pharmicell，攜手研發出珍貴SCCM成份，全面激活細胞，修護肌膚。將最高端科研成果，昇華至極致護膚層次。</p>'
                }, {
                    _id: '5b65c91aba67f7df334a945f',
                    direction: 'bottom',
                    to: '/',
                    title: "<h1 style='margin-bottom: 25px; font-weight: 500; font-size: 3.5rem; color: #b79a6e; '>秀妍金箔系列</h1>",
                    image: './image_gold.png',
                    description: '<p>百年韓方草本醫學經典</p><p>東醫寶鑒編錄古代肌膚調理智慧，講求陰陽五行調和，著重氣血運行；當中記載運用大自然中各種珍貴草本藥材，經長時間熬煮調配成韓方複合成分，達至臓腑相互協調之效。</p>'
                }]
            },

            featuredTreatments() {
                return [{
                    _id: '5b65c96f87970564a28d7acb',
                    direction: 'right',
                    to: '/',
                    title: "<img src='../../assets/treatment_title_home.png' alt='Ultra 360 Femme' style='width: 100%; margin-bottom: 20px; '>",
                    image: './treatment_home.png',
                    description: ''
                }]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @mixin md {
        @media (max-width: 767px) {
            @content;
        }
    }

    @mixin sm {
        @media (max-width: 575px) {
            @content;
        }
    }

    .home {

        & > .bg-filter {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            background-image: url("../../assets/intro_bg.png");
            background-repeat: no-repeat;
            background-size: cover;

            z-index: -1;
        }

        & > .headline {
            height: 100vh;
            z-index: 9;

            .clip-wrapper {
                bottom: 0;
                right: 0;

                @include md {
                    right: -25%;
                }

                @include sm {
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                }

                & > img {
                    height: 40vh;
                    width: auto;

                    @include md {
                        height: 35vh;
                    }

                    @include sm {
                        height: 30vh;
                    }
                }
            }

            h1 {
                letter-spacing: 25px;

                @include md {
                    letter-spacing: 15px;
                    font-size: 32px;
                }

                @include sm {
                    letter-spacing: 7.5px;
                    font-size: 26px;
                }
            }
        }

        & > .intro {
            min-height: 500px;
            color: rgba(113, 113, 113, 1);
            z-index: 9;

            .clip-wrapper {
                background-color: rgba(255, 255, 255, 0.5)
            }
        }

        & > .featurettes {
            background-color: rgba(255, 255, 255, 1);
        }
    }
</style>
